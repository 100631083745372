import cert from "./cgy.jpg";
import "./lab-confirmation.css";

export function CGy() {
  return (
    <div className="lab-confirm">
      <div className="d-flex">
        <div className="flex-1 text-left">
          <p>Országos Foglalkozás-Egészségügyi Szolgálat Kft.</p>
          <p>Company registration number: 13-09-165927</p>
          <p>Tax number: 11859059-2-13</p>
        </div>
        <div className="flex-1 text-left">
          <p>Registered office: 2724 Újlengyel, Határ út 12. (Hungary)</p>
          <p>Operating licence number: BP-11/NEO/14190-2/2021</p>
          <p>Health care provider identification number: 110446</p>
        </div>
      </div>
      <div className="text-center text-bold mt-4">
          The Országos Foglalkozás-Egészségügyi Szolgálat Kft. confirms that the following medical report is valid and identical to the paper version. 
      </div>
      <img src={cert} alt="cziner györgy certificate" class="responsive " />
    </div>
  );
}
