import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import { Clone } from "./covid-card/-/az/clone.component";
import { Clone2 } from "./covid-card/-/az/clone2.component";
import { CGy } from "./lab-confirmation/cgy.component";

function App() {
  const token1 = `eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJFRVNaVCIsIn1YiL6ljl0MjEwMTc0NDI2MDAzNDlyOC4xliwiaWQiOJM2ODAxNzEyNjs9.6yL7iyTwJbxgz8MyYEHvQmnzzIRM0K9eV02D5F_kn1M`;
  const token2 = `eyJhbGciOiJIUzI6NiJ9.eyJpc3NiOiJFRVNaVCIsIn1YiL6ljl0MjEwMTc0NDI2MDAzNDlyOC4xliwiaWQiOJM2ODAxNzEyNjs9.6yL7iyTwJbxgz9MyYEHvQmnzzIRM0K9eV02D5F_kn1I`;

  const antigenToken = `5c52ab385ad78he4789126n12da58ef0`;

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={`/covid-card/-/az/${token1}`}>
            <Clone />
          </Route>
          <Route path={`/covid-card/-/az/${token2}`}>
            <Clone2 />
          </Route>
          <Route path={`/lab-confirmation/${antigenToken}`}>
            <CGy />
          </Route>
          <Route path="/">
            <div className="no-bg"></div>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
