import './style.css'

export function Clone2(){
return(
    <>
        <a href="#main-content" id="skip-to-content">Ugrás a tartalomhoz</a>
    
        <div className="portlet-boundary portlet-boundary_145_  portlet-static portlet-static-end portlet-borderless portlet-dockbar "
            id="p_p_id_145_">
            <span id="p_145"></span>
            <div className="portlet-borderless-container">
                <div className="portlet-body">
    
                    <div className="navbar navbar-static-top dockbar" id="_145_dockbar" data-namespace="_145_">
                        <div className="navbar-inner">
                            <div className="container">
                                <span id="_145_dockbarResponsiveButton">
                                    <a className="btn btn-navbar" id="_145_navSiteNavigationNavbarBtn"
                                        data-navid="_145_navSiteNavigation" tabindex="0"><i className="icon-reorder"></i></a><a
                                        className="btn btn-navbar" id="_145_navAccountControlsNavbarBtn"
                                        data-navid="_145_navAccountControls" tabindex="0"><i className="icon-user"></i></a>
                                </span>
    
                                <span id="_145_dockbarbodyContent">
    
                                    <div className="collapse nav-collapse" id="_145_navSiteNavigationNavbarCollapse">
    
    
                                        <ul aria-label="Dokk" className="nav nav-navigation" id="_145_navSiteNavigation"
                                            role="menubar">
    
                                        </ul>
    
    
                                    </div>
    
                                    <div className="collapse nav-collapse" id="heading">
    
                                        <h1 className="portal-header-title">EESZT Lakossági Portál</h1>
    
                                    </div>
    
    
                                    <div className="collapse nav-collapse" id="_145_navAccountControlsNavbarCollapse">
    
    
                                        <ul aria-label="Dokk" className="nav nav-account-controls" id="_145_navAccountControls"
                                            role="menubar">
    
    
                                            <li className="divider-vertical " id="xczp_" role="presentation">
    
    
                                            </li>
    
    
                                            <li className="sign-in " id="bekq_" role="presentation">
    
    
                                                <a className="" data-redirect="true" href="/kau" id="" role="menuitem"
                                                    title="Bejelentkezés">
    
    
    
    
                                                    <i className="icon-user"></i>
    
    
                                                    <span className="nav-item-label">
                                                        Bejelentkezés
                                                    </span>
    
    
    
                                                </a>
    
    
                                            </li>
    
                                        </ul>
    
    
                                    </div>
    
    
                                    <div className="collapse nav-collapse" id="help">
                                        <a className="portal-header-help" href="/help"><i
                                                className="fa fa-question-circle fa-2x"></i></a>
                                    </div>
    
    
                                </span>
    
    
                            </div>
                        </div>
                    </div>
    
                    <div className="dockbar-messages" id="_145_dockbarMessages">
                        <div className="header"></div>
    
                        <div className="body"></div>
    
                        <div className="footer"></div>
                    </div>
    
    
                </div>
    
            </div>
    
        </div>
    
    
        <div className="container-fluid" id="wrapper">
            <header id="banner" role="banner">
                <div id="header">
                    <div id="header-controls" className="row-fluid no-space">
                        <div className="header-navigation span11">
                            <div id="navigation-container">
                                <nav className="sort-pages modify-pages" id="navigation" role="navigation">
                                    <ul className="ehealth-nav" aria-label="Webhelyoldalak:" role="menubar"
                                        id="yui_patched_v3_11_0_1_1620920705198_193">
    
    
                                        <li className="" id="layout_2" role="presentation">
    
                                            <a aria-labelledby="layout_2" href="https://www.eeszt.gov.hu/hu/nyito-oldal"
                                                role="menuitem" tabindex="0"
                                                id="yui_patched_v3_11_0_1_1620920705198_212"><span>
    
    
                                                    Főoldal</span></a>
    
                                        </li>
    
                                        <li className="dropdown" id="layout_61" role="presentation">
    
                                            <a aria-labelledby="layout_61" href="javascript:void(0);" aria-haspopup="true"
                                                role="menuitem" tabindex="-1"
                                                id="yui_patched_v3_11_0_1_1620920705198_214"><span>
    
    
                                                    Aktualitások</span></a>
    
                                            
                                        </li>
    
    
    
    
    
                                        <li className="" id="layout_83" role="presentation">
    
                                            <a aria-labelledby="layout_83"
                                                href="https://www.eeszt.gov.hu/hu/torzspublikacio" role="menuitem"
                                                tabindex="-1" id="yui_patched_v3_11_0_1_1620920705198_224"><span>
    
    
                                                    Törzspublikáció</span></a>
    
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
    
                        <div className="header-controls-right span1">
                            <span className="psight" title="Gyengénlátó verzió"></span>
                        </div>
                    </div>
                </div>
            </header>
    
            <div id="content">
    
    
                <div className="columns-1" id="main-content" role="main">
                    <div className="portlet-layout row-fluid">
                        <div className="portlet-column portlet-column-only span12" id="column-1">
                            <div className="portlet-dropzone portlet-column-content portlet-column-content-only"
                                id="layout-column_column-1">
    
    
    
                                <div className="portlet-boundary portlet-boundary_eesztcovidcardportlet_WAR_eesztcovidcardportlet_  portlet-static portlet-static-end portlet-borderless  "
                                    id="p_p_id_eesztcovidcardportlet_WAR_eesztcovidcardportlet_">
                                    <span id="p_eesztcovidcardportlet_WAR_eesztcovidcardportlet"></span>
    
    
                                    <div className="portlet-borderless-container">
    
    
                                        <div className="portlet-body">
    
    
                                            <div className="columns-1" id="main-content" role="main">
    
    
                                                <div id="tiqp-1" className="tiqp-div">
                                                    <p className="eeszt-portlet-layout-h1">
                                                        Védettségi igazolvány ellenőrzés
                                                    </p>
                                                    <p className="eeszt-portlet-layout-h1">
                                                        COVID-card validation
                                                    </p>
                                                </div>
    
    
    
    
    
                                                <table className="table">
                                                    <tbody className="table-data">
    
    
                                                        <tr className="table-row">
                                                            <td className="table-cell"><b>Kiállítva/Issued:</b></td>
                                                            <td className="table-cell">Kiállítva/Issued</td>
                                                        </tr>
    
                                                        <tr className="table-row">
                                                            <td className="table-cell"><b>Név/Name:</b></td>
                                                            <td className="table-cell">CZINER GYÖRGYNÉ</td>
                                                        </tr>
    
                                                        <tr className="table-row">
                                                            <td className="table-cell"><b>Oltás dátuma/Vaccination date:</b>
                                                            </td>
                                                            <td className="table-cell">2021.04.18</td>
                                                        </tr>
    
                                                        <tr className="table-row">
                                                            <td className="table-cell"><b>Védettség/Target:</b></td>
                                                            <td className="table-cell">SARS-CoV-2</td>
                                                        </tr>
    
                                                        <tr className="table-row">
                                                            <td className="table-cell"><b>Okmány azonosító/Personal
                                                                    identification:</b></td>
                                                            <td className="table-cell">357757AH</td>
                                                        </tr>
    
    
    
    
                                                        <tr className="table-row">
                                                            <td className="table-cell"><b>Kártya érvényessége/Card validity:</b>
                                                            </td>
                                                            <td className="table-cell">
                                                                <p style={{color: "green"}}>
                                                                    érvényes/valid
                                                                </p>
                                                            </td>
                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-icon" colspan="2">
                                                                {/* <svg xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="16"
                                                                    height="16" preserveAspectRatio="xMidYMid meet"
                                                                    viewBox="0 0 16 16"
                                                                    style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);">
                                                                    <g fill="green">
                                                                        <path fill-rule="evenodd"
                                                                            d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z">
                                                                        </path>
                                                                        <path fill-rule="evenodd"
                                                                            d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093l3.473-4.425a.236.236 0 0 1 .02-.022z">
                                                                        </path>
                                                                    </g>
                                                                    <rect x="0" y="0" width="16" height="16"
                                                                        fill="rgba(0, 0, 0, 0)"></rect>
                                                                </svg> */}
                                                            </td>
                                                        </tr>
    
    
    
                                                    </tbody>
                                                </table>
    
    
                                            </div>
    
    
                                        </div>
    
                                    </div>
    
    
                                </div>
    
    
    
    
                            </div>
                        </div>
                    </div>
                </div>
    
    
                <div className="portlet-boundary portlet-boundary_cookiesnotification_WAR_cookiesnotificationportlet_  portlet-static portlet-static-end portlet-borderless cookies-notification-portlet "
                    id="p_p_id_cookiesnotification_WAR_cookiesnotificationportlet_">
                    <span id="p_cookiesnotification_WAR_cookiesnotificationportlet"></span>
                    {/* <portlet:defineobjects>
                        <div id="cookies" className="cookies cookiesShow">
                            <div className="notifications-button">
                            </div>
                            <div className="notifications-content">
                            </div>
                        </div>
                    </portlet:defineobjects> */}
                </div>
    
                <div className="portlet-boundary portlet-boundary_1_WAR_kaleodesignerportlet_  portlet-static portlet-static-end portlet-borderless kaleo-designer-portlet "
                    id="p_p_id_1_WAR_kaleodesignerportlet_">
                    <span id="p_1_WAR_kaleodesignerportlet"></span>
                </div>
    
                <div className="portlet-boundary portlet-boundary_tsystemsiqportaltaglibglobalportlet_WAR_tsystemsiqportaltaglibglobalportlet_  portlet-static portlet-static-end portlet-borderless  "
                    id="p_p_id_tsystemsiqportaltaglibglobalportlet_WAR_tsystemsiqportaltaglibglobalportlet_">
                    <span id="p_tsystemsiqportaltaglibglobalportlet_WAR_tsystemsiqportaltaglibglobalportlet"></span>
                </div>
    
                <div className="portlet-boundary portlet-boundary_eesztportalbongeszoellenorzesportlet_WAR_eesztportalbongeszoellenorzesportlet_  portlet-static portlet-static-end portlet-borderless eeszt-portal-bongeszoellenorzes-portlet "
                    id="p_p_id_eesztportalbongeszoellenorzesportlet_WAR_eesztportalbongeszoellenorzesportlet_">
                    <span id="p_eesztportalbongeszoellenorzesportlet_WAR_eesztportalbongeszoellenorzesportlet"></span>
                </div>
    
                <div className="portlet-boundary portlet-boundary_eesztportalsamlervenyessegportlet_WAR_eesztportalsamlervenyessegportlet_  portlet-static portlet-static-end portlet-borderless eeszt-portal-samlervenyesseg-portlet "
                    id="p_p_id_eesztportalsamlervenyessegportlet_WAR_eesztportalsamlervenyessegportlet_">
                    <span id="p_eesztportalsamlervenyessegportlet_WAR_eesztportalsamlervenyessegportlet"></span>
                </div>
    
                <div className="portlet-boundary portlet-boundary_eesztportaljelszolejaratportlet_WAR_eesztportaljelszolejaratportlet_  portlet-static portlet-static-end portlet-borderless eeszt-portal-jelszolejarat-portlet "
                    id="p_p_id_eesztportaljelszolejaratportlet_WAR_eesztportaljelszolejaratportlet_">
                    <span id="p_eesztportaljelszolejaratportlet_WAR_eesztportaljelszolejaratportlet"></span>
                </div>
    
                <div className="portlet-boundary portlet-boundary_csrfguardglobalportlet_WAR_csrfguardglobalportlet_  portlet-static portlet-static-end portlet-borderless  "
                    id="p_p_id_csrfguardglobalportlet_WAR_csrfguardglobalportlet_">
                    <span id="p_csrfguardglobalportlet_WAR_csrfguardglobalportlet"></span>
                </div>
    
    
                <form action="#" id="hrefFm" method="post" name="hrefFm">
                    <span></span>
                </form>
            </div>
    
            <footer className="portlet-layout row-fluid" id="main-footer">
                <div className="portlet-column portlet-column-only span12" id="column-foot">
                    <div className="portlet-boundary portlet-boundary_eesztportallablecportlet_WAR_eesztportallablecportlet_  portlet-static portlet-static-end portlet-borderless lablec-portlet "
                        id="p_p_id_eesztportallablecportlet_WAR_eesztportallablecportlet_">
                        <span id="p_eesztportallablecportlet_WAR_eesztportallablecportlet"></span>
                        <div className="portlet-borderless-container">
                            <div className="portlet-body">
    
                                <div className="row-fluid" id="contacts">
                                    <div className="span4" id="footer-address">Országos Kórházi Főigazgatóság<br />
                                        1125 Budapest, Diós árok 3.</div>
    
                                    <div className="span4" id="footer-phone">+36 1 920 1050</div>
    
                                    <div className="span4" id="footer-email">&nbsp;<span style={{fontSize: '15px'}}><a
                                                href="mailto:helpdesk.eeszt@okfo.gov.hu">helpdesk.eeszt@okfo.gov.hu</a></span>
                                    </div>
                                </div>
    
                                <div className="row-fluid">
                                    <div className="span4">
                                        <ul>
                                            <li><a href="/web/guest/fenntarto">Fenntartó</a></li>
                                            <li><a href="/web/guest/adatvedelem">Adatvédelem</a></li>
                                            <li><a href="/web/guest/impresszum">Impresszum</a></li>
                                        </ul>
                                    </div>
    
                                    <div className="span4">
                                        <p style={{fontSize:"12px"}}>Az EESZT adatkezelését a NAIH auditálta <span className="audit">NAIH</span></p>
                                    </div>
    
                                    <div className="span4">
                                        <p style={{lineHeight: "normal", paddingTop: "22px"}}>Országos Kórházi Főigazgatóság ©
                                            2021<br />
                                            Minden jog fenntartva</p>
                                    </div>
                                </div>
    
    
                            </div>
    
                        </div>
                    </div>
                </div>               
            </footer>
    
            <button id="szechenyi-toggle" className="btn btn-primary"><i className="fa fa-chevron-down"></i></button>
            <div id="szechenyi" className=""></div>
        </div>
</>
)
}